export default [
  {
    id: 'Dashboard',
    icon: 'home',
    title: 'MENU.HOME',
    to: '/dashboard/classic-dashboard',
  },
  {
    id: 'Academico',
    icon: 'book-open',
    title: 'MENU.ACADEMICO',
    children: [
      /*
      {
          id: 'Academico',
          icon: 'book-open',
          title: 'MENU.ACADEMICO',
          to: '/academico'
      },
      */
      {
        id: 'Matriculas',
        icon: 'circle',
        title: 'MENU.MATRICULAS',
        to: '/academico/matriculas/',
      },
      {
        id: 'Rematriculas',
        icon: 'circle',
        title: 'MENU.REMATRICULAS',
        to: '/academico/rematriculas/',
      },
      {
        id: 'Contratos',
        icon: 'circle',
        title: 'MENU.CONTRATOS',
        to: '/contratos/',
      },
      {
        id: 'AditivosContrato',
        icon: 'circle',
        title: 'MENU.ADITIVOS_CONTRATO',
        to: '/contratos/aditivos',
      },
      {
        id: 'GerenciadorTurmas',
        icon: 'circle',
        title: 'MENU.GERENCIADOR_TURMAS',
        to: '/gerenciador-turmas',
      },
      {
        id: 'PainelProfessor',
        icon: 'circle',
        title: 'MENU.PAINEL_PROFESSOR',
        to: '/painel-professor',
      },
      // {
      //   id: 'PainelCoordenacao',
      //   icon: 'circle',
      //   title: 'MENU.PAINEL_COORDENACAO',
      //   to: '/painel-coordenacao',
      // },
      {
        id: 'Cursos',
        icon: 'circle',
        title: 'MENU.CURSOS',
        to: '/academico/estrutura/cursos',
      },
      {
        id: 'GruposTurma',
        icon: 'circle',
        title: 'MENU.GRUPOS_TURMA',
        to: '/academico/cej/grupos-turma',
      },
      {
        id: 'Disciplinas',
        icon: 'circle',
        title: 'MENU.DISCIPLINAS',
        to: '/academico/cej/disciplinas',
      },
      {
        id: 'Turmas',
        icon: 'circle',
        title: 'MENU.TURMAS',
        to: '/academico/cej/turmas',
      },
      {
        id: 'PeriodoLetivo',
        icon: 'circle',
        title: 'MENU.PERIODO_LETIVO',
        to: '/periodo-letivo',
      },
      {
        id: 'FechamentoPeriodoLetivo',
        icon: 'circle',
        title: 'MENU.FECHAMENTO_PERIODO_LETIVO',
        to: '/fechamento-periodo-letivo',
      },
      {
        id: 'FechamentoCursosTurmas',
        icon: 'circle',
        title: 'MENU.FECHAMENTO_CURSOS_TURMAS',
        to: '/fechamento-cursos-turmas',
      },
      {
        id: 'DocentesPeriodo',
        icon: 'circle',
        title: 'MENU.DOCENTES_PERIODO',
        to: '/academico/docentes-periodo',
      },
      {
        id: 'Boletins',
        icon: 'circle',
        title: 'MENU.BOLETINS',
        to: '/academico/boletins',
      },
      {
        id: 'Aulas',
        icon: 'circle',
        title: 'MENU.AULAS',
        to: '/academico/diario-classe/aulas',
      },
      {
        id: 'Frequencias',
        icon: 'circle',
        title: 'MENU.FREQUENCIAS',
        to: '/academico/diario-classe/frequencias',
      },
      {
        id: 'Avaliacoes',
        icon: 'circle',
        title: 'MENU.AVALIACOES',
        to: '/academico/diario-classe/avaliacoes',
      },
      {
        id: 'Notas',
        icon: 'circle',
        title: 'MENU.NOTAS',
        to: '/academico/diario-classe/notas',
      },
      {
        id: 'RelatorioDisciplinas',
        icon: 'circle',
        title: 'MENU.RELATORIO_DISCIPLINA',
        to: '/relatorio-disciplinas',
      },
      {
        id: 'RelatorioTurmasParaAluno',
        icon: 'circle',
        title: 'MENU.RELATORIO_TURMAS_PARA_ALUNO',
        to: '/relatorio-turmas-para-aluno',
      },
      {
        id: 'RelatoriosTurmas',
        icon: 'circle',
        title: 'MENU.RELATORIOS_TURMAS',
        to: '/relatorios-turmas',
      },
      {
        id: 'TiposDeAvalicao',
        icon: 'circle',
        title: 'MENU.TIPO_DE_AVALIACAO',
        to: '/academico/cej/tipo-avaliacao',
      },
      {
        id: 'DocumentoPadrao',
        icon: 'circle',
        title: 'MENU.DOCUMENTO_PADRAO',
        to: '/academico/cej/documento-padrao',
      },
      {
        id: 'RelatorioDescontosParaContratos',
        icon: 'circle',
        title: 'MENU.RELATORIO_DESCONTOS_PARA_CONTRATOS',
        to: '/relatorio-descontos-para-contratos',
      },
      {
        id: 'RelatorioValoresParaContratos',
        icon: 'circle',
        title: 'MENU.RELATORIO_VALORES_PARA_CONTRATOS',
        to: '/relatorio-valores-para-contratos',
      },
      {
        id: 'RelatorioSituacaoContrato',
        icon: 'circle',
        title: 'MENU.RELATORIO_SITUACAO_CONTRATO',
        to: '/relatorios-situacao-contrato',
      },
      {
        id: 'RelatorioIrmaos',
        icon: 'circle',
        title: 'MENU.RELATORIO_IRMAOS',
        to: '/relatorio-irmaos',
      },
      {
        id: 'RelatoriosHistoricoEscolar',
        icon: 'circle',
        title: 'MENU.RELATORIOS_HISTORICO_ESCOLAR',
        to: '/relatorios-historico-escolar',
      },
      {
        id: 'RelatorioTotalizadores',
        icon: 'circle',
        title: 'MENU.RELATORIO_TOTALIZADORES',
        to: '/relatorio-totalizadores',
      },
      {
        id: 'RelatorioFichaSocioeconomica',
        icon: 'circle',
        title: 'MENU.FICHA_SOCIOECONOMICA',
        to: '/relatorio-ficha-socioeconomica',
      },
    ],
  },
  {
    id: 'CurriculoEscolar',
    icon: 'file-text',
    title: 'MENU.CURRICULO_ESCOLAR',
    children: [
      {
        id: 'InstituicoesEnsino',
        icon: 'circle',
        title: 'MENU.INSTITUICOES_ENSINO',
        to: '/instituicao-ensino',
      },
      {
        id: 'InstituicoesEnsinoTurmas',
        icon: 'circle',
        title: 'MENU.TURMAS',
        to: '/instituicao-ensino/turmas',
      },
      {
        id: 'InstituicoesEnsinoDisciplinas',
        icon: 'circle',
        title: 'MENU.DISCIPLINAS',
        to: '/instituicao-ensino/disciplinas',
      },
      {
        id: 'InstituicoesEnsinoHistorico',
        icon: 'circle',
        title: 'MENU.HISTORICO_ESCOLAR',
        to: '/instituicao-ensino/historico-escolar',
      },
    ],
  },
  {
    id: 'Financeiro',
    icon: 'dollar-sign',
    title: 'MENU.FINANCEIRO',
    children: [
      {
        id: 'Bancos',
        icon: 'circle',
        title: 'MENU.BANCOS',
        to: '/financeiro/estrutura/bancos',
      },
      {
        id: 'Contas',
        icon: 'circle',
        title: 'MENU.CONTAS',
        to: '/financeiro/estrutura/contas',
      },
      {
        id: 'FormasPagamento',
        icon: 'circle',
        title: 'MENU.FORMAS_PAGAMENTO',
        to: '/financeiro/estrutura/formas-pagamento',
      },
      {
        id: 'Precos',
        icon: 'circle',
        title: 'MENU.PRECOS',
        to: '/financeiro/estrutura/precos',
      },
      {
        id: 'Descontos',
        icon: 'circle',
        title: 'MENU.DESCONTOS',
        to: '/financeiro/estrutura/descontos',
      },
      // {
      //   id: 'Impostos',
      //   icon: 'circle',
      //   title: 'MENU.IMPOSTOS',
      //   to: '/financeiro/estrutura/impostos',
      // },
      {
        id: 'ContasPagar',
        icon: 'circle',
        title: 'MENU.LANCAMENTO_CONTAS_PAGAR',
        to: '/financeiro/contas-pagar',
      },
      {
        id: 'ContasReceber',
        icon: 'circle',
        title: 'MENU.LANCAMENTO_CONTAS_RECEBER',
        to: '/financeiro/contas-a-receber',
      },
      {
        id: 'LancamentosHistorico',
        icon: 'circle',
        title: 'MENU.HISTORICO_LANCAMENTOS',
        to: '/financeiro/lancamentos/historico',
      },
      {
        id: 'IntegracaoFinanceiraContabil',
        icon: 'circle',
        title: 'MENU.INTEGRACAO_FINANCEIRA_CONTABIL',
        to: '/financeiro/lancamentos/integracao-contabil',
      },
      {
        id: 'PeriodoFinanceiro',
        icon: 'circle',
        title: 'MENU.PERIODO_FINANCEIRO',
        to: '/financeiro/caixa/periodo-financeiro',
      },
      {
        id: 'FluxoCaixa',
        icon: 'circle',
        title: 'MENU.FLUXO_CAIXA',
        to: '/financeiro/caixa/fluxo',
      },
      {
        id: 'CartaCobranca',
        icon: 'circle',
        title: 'MENU.COBRANCA',
        to: '/financeiro/cobranca',
      },
      {
        id: 'Fornecedores',
        icon: 'circle',
        title: 'MENU.FORNECEDORES',
        to: '/financeiro/estrutura/fornecedores',
      },
      {
        id: 'RelatorioAnaliseRetroativaContasPagar',
        icon: 'circle',
        title: 'MENU.IMPRIMIR_RELATORIO_ANALISE_RETROATIVA_CONTAS_PAGAR',
        to: '/financeiro/relatorio/contas-a-pagar/analise-retroativa',
      },
      {
        id: 'RelatorioContaReceberAnaliseRetroativa',
        icon: 'circle',
        title: 'MENU.IMPRIMIR_RELATORIO_ANALISE_RETROATIVA_CONTAS_RECEBER',
        to: '/financeiro/relatorio/contas-a-receber/analise-retroativa',
      },
      {
        id: 'RelatorioFaturamentoDeclaracaoImpostoDeRenda',
        icon: 'circle',
        title: 'MENU.RELATORIO_DECLARACAO_IMPOSTO_RENDA',
        to: '/financeiro/relatorio/faturamento/declaracao-imposto-renda',
      },
      {
        id: 'RelatorioFaturamento',
        icon: 'circle',
        title: 'MENU.RELATORIO_FATURAMENTO',
        to: '/financeiro/relatorio/faturamento',
      },
    ],
  },
  {
    id: 'Contábil',
    icon: 'box',
    title: 'MENU.CONTABIL',
    children: [
      {
        id: 'PlanoContas',
        icon: 'circle',
        title: 'MENU.PLANO_CONTAS',
        to: '/contabil/plano-contas',
      },
      {
        id: 'PeriodosContabeis',
        icon: 'circle',
        title: 'MENU.PERIODOS_CONTABEIS',
        to: '/contabil/periodos',
      },
      {
        id: 'LancamentosContabeis',
        icon: 'circle',
        title: 'MENU.LANCAMENTOS_CONTABEIS',
        to: '/contabil/lancamentos',
      },
      // {
      //   id: 'FechamentoContabil',
      //   icon: 'circle',
      //   title: 'MENU.FECHAMENTO_CONTABIL',
      //   to: '/contabil/fechamento',
      // },
      {
        id: 'ContabilRelatorio',
        icon: 'circle',
        title: 'MENU.RELATORIO_LANCAMENTOS_POR_PERIODO',
        to: '/contabil/lancamento-por-periodo',
      },
      {
        id: 'ContabilBalancete',
        icon: 'circle',
        title: 'MENU.RELATORIO_BALANCETE',
        to: '/contabil/balancete',
      },
      {
        id: 'ContabilDiario',
        icon: 'circle',
        title: 'MENU.RELATORIO_DIARIO',
        to: '/contabil/diario',
      },
      {
        id: 'ContabilRazao',
        icon: 'circle',
        title: 'MENU.RELATORIO_RAZAO',
        to: '/contabil/razao',
      },
    ],
  },
  {
    id: 'Suprimentos',
    icon: 'inbox',
    title: 'MENU.SUPRIMENTOS',
    children: [
      {
        id: 'LancamentosNf',
        icon: 'circle',
        title: 'MENU.LANCAMENTOS_NF',
        to: '/suprimentos/lancamentos-nf',
      },
      {
        id: 'Estoque',
        icon: 'circle',
        title: 'MENU.ESTOQUE',
        to: '/suprimentos/estoques',
      },
      {
        id: 'RelatorioEstoque',
        icon: 'circle',
        title: 'MENU.RELATORIO_ESTOQUE',
        to: '/suprimentos/relatorio-estoque',
      },
      {
        id: 'Depositos',
        icon: 'circle',
        title: 'MENU.DEPOSITOS',
        to: '/suprimentos/depositos',
      },
      {
        id: 'Produtos',
        icon: 'circle',
        title: 'MENU.PRODUTOS',
        to: '/suprimentos/produtos',
      },
    ],
  },
  {
    id: 'Admin',
    icon: 'users',
    title: 'MENU.RECURSOS_HUMANOS',
    children: [
      {
        id: 'Admin',
        icon: 'circle',
        title: 'MENU.PESSOAS',
        to: '/pessoas',
      },
      {
        id: 'funcionarios',
        icon: 'circle',
        title: 'MENU.FUNCIONARIOS',
        to: '/funcionarios',
      },
      {
        id: 'protocolos',
        icon: 'circle',
        title: 'MENU.PROTOCOLOS',
        to: '/protocolos',
      },
      {
        id: 'Admin',
        icon: 'circle',
        title: 'MENU.USUARIOS',
        to: '/usuarios',
      },
      {
        id: 'Admin',
        icon: 'circle',
        title: 'MENU.PERFIS',
        to: '/perfis',
      },
    ],
  },
];
